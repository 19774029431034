import { AnimatePresence, motion } from "framer-motion"
import { RiCloseCircleFill } from "react-icons/ri"
import useGlobalStore from "stores/global"
import DragClose from "../DragClose"
import Portal from "components/Portal"

interface Props {
  children: JSX.Element | JSX.Element[]
  close: () => void
  modalOpen: boolean
  disableDragUp: boolean
}

const FullCardModal = ({
  children,
  modalOpen,
  close,
  disableDragUp,
}: Props) => {
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  return (
    <AnimatePresence
      // Disable any initial animations on children that
      // are present when the component is first rendered
      initial={false}
      // Only render one component at a time.
      // The exiting component will finish its exit
      // animation before entering component is rendered
      mode="wait" // Fires when all exiting nodes have completed animating out
      onExitComplete={() => null}
    >
      {modalOpen && (
        <motion.div
          onClick={close}
          className="backdrop backdrop-center"
          initial={animationToggle && { opacity: 0 }}
          animate={animationToggle && { opacity: 1 }}
          exit={animationToggle ? { opacity: 0 } : undefined}
          transition={animationToggle ? { duration: 0.15 } : undefined}
        >
          <motion.div onClick={(e) => e.stopPropagation()}>
            <DragClose disableDragUp={disableDragUp} close={close}>
              <motion.div onClick={close} className="d-flex">
                <RiCloseCircleFill
                  onClick={close}
                  size={40}
                  color="#fff"
                  role="button"
                  className="m-3 ms-auto me-4"
                />
              </motion.div>
              {children}
            </DragClose>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default FullCardModal
