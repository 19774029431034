import { useEffect, useState } from "react"
import Image, { ImageProps } from "next/image"
import imagePlaceholder from "utils/imagePlaceholder"

export const FallbackImage = ({ src, alt, ...rest }: ImageProps) => {
  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <Image
      {...rest}
      src={imgSrc ? imgSrc : imagePlaceholder()}
      onError={() => {
        setImgSrc(imagePlaceholder(undefined, undefined, undefined, true))
      }}
      alt={alt}
    />
  )
}
