import Image from "next/image"
import useGlobalStore from "stores/global"
import { AppInterface } from "types/types"
import imagePlaceholder from "utils/imagePlaceholder"
import AppActionArea from "./AppActionArea"
import DetailsArea from "./DetailsArea"

interface Props {
  app?: AppInterface
}

const MobileMainAppCard = ({ app }: Props) => {
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <div className="main-app-card">
      <div
        style={{ height: 350, position: "absolute", inset: 0, zIndex: 0 }}
        className="parallax"
      >
        <Image
          src={app?.screenshotUrls?.[0] || imagePlaceholder(isDark)}
          fill
          alt="app screenshot"
          style={{ objectFit: "cover" }}
          placeholder="blur"
          blurDataURL={imagePlaceholder(isDark)}
          priority
        />
        <div className="card-overlay app-bg-gradient-fade rounded-0"></div>
      </div>
      <div className="mx-3">
        <div
          className="position-relative mb-3"
          style={{
            height: "320px",
          }}
        >
          <AppActionArea app={app} isMobile={true} />
        </div>
        <div className="card card-style m-0 mb-4">
          <div className="card mb-0">
            <DetailsArea app={app} isMobile={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMainAppCard
