import { useEffect, useState } from "react"
import useT from "utils/useT"
import FullCardModal from "./FullCardModal"
import { motion } from "framer-motion"
import Image from "next/image"
import useGlobalStore from "stores/global"
import imagePlaceholder from "utils/imagePlaceholder"
import RenderIfVisible from "react-render-if-visible"

interface Props {
  screenshotUrls?: string[]
  ipadScreenshotUrls?: string[]
}
interface SelectedImage {
  src: string
  id: string
}

const Screenshots = ({ screenshotUrls, ipadScreenshotUrls }: Props) => {
  const t = useT()
  const [image, setImage] = useState<SelectedImage>()
  const isDark = useGlobalStore((state) => state.isDark)
  const [screenshots, setScreenshots] = useState(
    screenshotUrls
      ? {
          images: screenshotUrls,
          type: "iphone",
        }
      : { images: ipadScreenshotUrls, type: "ipad" },
  )
  useEffect(() => {
    setScreenshots(
      screenshotUrls
        ? {
            images: screenshotUrls,
            type: "iphone",
          }
        : { images: ipadScreenshotUrls, type: "ipad" },
    )
  }, [screenshotUrls, ipadScreenshotUrls])

  const animationToggle = useGlobalStore((state) => state.animationToggle)
  function shrinkDimensions(width: number, height: number) {
    // shrink dimensions while maintaining aspect ratio
    const max = 400
    const widthRatio = width / max
    const heightRatio = height / max

    if (width > height) {
      const slicedWidth = width / widthRatio
      const slicedHeight = height / widthRatio
      return { slicedWidth, slicedHeight }
    } else {
      const slicedWidth = width / heightRatio
      const slicedHeight = height / heightRatio
      return { slicedWidth, slicedHeight }
    }
  }
  function getScreenshot(link: string, dimension: "height" | "width") {
    const imageSize = link?.match(/\d+x\d+/)
    const imageDimensions = imageSize?.[0]?.split("x")
    const width = imageDimensions?.[0] ? parseInt(imageDimensions?.[0]) : 0
    const height = imageDimensions?.[0] ? parseInt(imageDimensions?.[1]) : 0
    const { slicedHeight, slicedWidth } = shrinkDimensions(width, height)
    return dimension === "height" ? slicedHeight : slicedWidth
  }
  return (
    <>
      <div className="card card-style screenshots">
        <div dir="auto" className="content mb-2 d-flex justify-content-between">
          <h4 className="font-600 mb-3">{t.previewApp.screenshots}</h4>
          {screenshotUrls &&
            screenshotUrls?.length > 1 &&
            ipadScreenshotUrls &&
            ipadScreenshotUrls?.length > 1 && (
              <div dir="ltr" className="btn-group w-50 mt-n1 my-3">
                <button
                  onClick={() =>
                    setScreenshots({ images: screenshotUrls, type: "iphone" })
                  }
                  className={`btn rounded-start btn-xxs ${
                    screenshots.type === "iphone"
                      ? "bg-blue-dark"
                      : "color-blue-light border-1 border-blue-light"
                  }`}
                >
                  iPhone
                </button>
                <button
                  onClick={() =>
                    setScreenshots({ images: ipadScreenshotUrls, type: "ipad" })
                  }
                  className={`btn rounded-end btn-xxs ${
                    screenshots.type === "ipad"
                      ? "bg-blue-dark"
                      : "color-blue-light border-1 border-blue-light"
                  }`}
                >
                  iPad
                </button>
              </div>
            )}
        </div>
        <RenderIfVisible>
          <motion.div
            layoutId={animationToggle ? "screenshots" : undefined}
            className="d-flex overflow-scroll gap-3 px-3 pb-3 align-items-center"
          >
            {screenshots.images?.map((screenshot: string, i: number) => (
              <motion.div
                key={i + "screenshot"}
                layoutId={animationToggle ? `screenshot-${i}` : undefined}
              >
                <Image
                  height={getScreenshot(screenshot, "height")}
                  width={getScreenshot(screenshot, "width")}
                  onClick={() =>
                    setImage({ src: screenshot, id: i.toString() })
                  }
                  src={screenshot}
                  alt="app screenshots"
                  className="rounded-m shadow-l"
                  placeholder="blur"
                  blurDataURL={imagePlaceholder(isDark)}
                  unoptimized
                />
              </motion.div>
            ))}
          </motion.div>
        </RenderIfVisible>
      </div>
      <FullCardModal
        disableDragUp={true}
        modalOpen={!!image}
        close={() => setImage(undefined)}
      >
        <motion.div
          layoutId={animationToggle ? "screenshots" : undefined}
          className="d-flex overflow-scroll mx-2 mb-2 pb-3 align-items-center vw-100"
        >
          {screenshots.images?.map((screenshot: string, i: number) => (
            <motion.div
              key={i + "screenshot"}
              layoutId={animationToggle ? `screenshot-${i}` : undefined}
            >
              <motion.img
                src={screenshot}
                className="rounded-m mx-2 shadow-l"
                style={{
                  height:
                    "calc(100vh - 220px - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
                }}
                alt="app screenshots"
              />
            </motion.div>
          ))}
        </motion.div>
      </FullCardModal>
    </>
  )
}

export default Screenshots
