import React from "react"
import useT from "utils/useT"
import { motion } from "framer-motion"
import useGlobalStore from "stores/global"
type Props = {
  hasMonthlySubscriptions?: boolean
  hasInAppPurchases?: boolean
  isFree: boolean
}

const InAppPurchasesChip = ({
  hasMonthlySubscriptions,
  hasInAppPurchases,
  isFree,
}: Props) => {
  const t = useT()
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  if (hasMonthlySubscriptions === undefined || hasInAppPurchases === undefined)
    return null
  return (
    <motion.div
      initial={
        animationToggle && {
          y: 30,
          opacity: 0,
        }
      }
      animate={
        animationToggle && {
          y: [30, -5, 0],
          opacity: 1,
        }
      }
      transition={
        animationToggle
          ? {
              duration: 0.3,
              type: "keyframes",
              ease: "easeOut",
              delay: 0.3,
            }
          : undefined
      }
      className={`font-11 color-white px-2 rounded-s app-detail-name ${
        hasMonthlySubscriptions
          ? "bg-orange-dark"
          : hasInAppPurchases
          ? "bg-yellow-dark"
          : "bg-green-dark"
      }`}
      style={{ width: "fit-content", maxWidth: "calc(100% - 75px)" }}
    >
      {hasMonthlySubscriptions
        ? t.previewApp.monthlySubscriptions
        : hasInAppPurchases
        ? t.previewApp.hasInAppPurchases
        : !hasMonthlySubscriptions && !hasInAppPurchases
        ? isFree
          ? t.previewApp.totallyFree
          : t.previewApp.noIAP
        : ""}
    </motion.div>
  )
}

export default InAppPurchasesChip
