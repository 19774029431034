import useRouterStore from "stores/routerStore"
import useGlobalStore from "../stores/global"
import { submitter, translatedDescription } from "../types/types"

interface Props {
  submitter?: submitter

  description: string | undefined
}

const DisplaySubmitterNote = ({ submitter, description }: Props) => {
  const locale = useRouterStore((state) => state.router?.locale)

  const submitterDescription = submitter?.submitterDescription
  const translateSubmitterNote = useGlobalStore(
    (state) => state.translateSubmitterNote,
  )
  const defaultTranslate = submitter?.translatedDescription
    ? submitter?.translatedDescription?.find((item: translatedDescription) =>
        locale?.includes(item.to),
      )
    : undefined
  if (
    defaultTranslate?.text &&
    submitterDescription &&
    submitterDescription?.length / 2 > defaultTranslate?.text?.length
  )
    return submitterDescription
  if (translateSubmitterNote && defaultTranslate) return defaultTranslate.text
  if (submitterDescription) return submitterDescription
  if (description) return description
  return "loading..."
}

export default DisplaySubmitterNote
