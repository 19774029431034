import Link from "next/link"
import { AppInterface } from "../types/types"
import Image from "next/image"
import displaySubmitterNote from "../utils/displaySubmitterNote"
import useT from "../utils/useT"
import { motion } from "framer-motion"
import useGlobalStore from "../stores/global"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"
import getLink from "utils/getLink"

const AppsListItem = ({
  trackId,
  artworkUrl512,
  trackName,
  genres,
  description,
  formattedPrice,
  submitterPrice,
  price,
  submitter,
  i,
  appRemoved,
  hasInAppPurchases,
}: AppInterface & { i?: number }) => {
  const t = useT()
  const isDark = useGlobalStore((state) => state.isDark)
  const pathname = useRouterStore((state) => state.router?.pathname)
  const query = useRouterStore((state) => state.router?.query)
  const showDiscountedPrice = Number(price) < Number(submitterPrice || 0)
  const animationToggle = useGlobalStore((state) => state.animationToggle)

  return (
    <Link
      as={trackId ? getLink(trackId, trackName) : ""}
      href={
        trackId
          ? {
            pathname: pathname,
            query: {
              ...query,
              appId: trackId,
            },
          }
          : ""
      }
      key={trackId + "app-list-item-link"}
      scroll={false}
      className="d-block py-2"
    >
      <motion.div
        initial={animationToggle && { transform: "scale(0.8)", opacity: 0 }}
        animate={
          animationToggle && {
            transform: ["scale(0.9)", "scale(1.01)", "scale(1)"],
            opacity: 1,
          }
        }
        transition={
          animationToggle
            ? {
              duration: 0.1,
              type: "keyframes",
              ease: "easeOut",
            }
            : undefined
        }
        className="d-flex"
      >
        <Image
          src={artworkUrl512 || imagePlaceholder(isDark)}
          alt="app icon"
          className="app-img align-self-center"
          width={65}
          height={65}
          priority={Boolean(i && i < 4)}
          placeholder="blur"
          blurDataURL={imagePlaceholder(isDark)}
          unoptimized
        />
        <div className="align-self-center mx-3 d-flex flex-column overflow-auto">
          <h3
            className="font-500 font-15 text-nowrap overflow-hidden"
            style={{ textOverflow: "ellipsis" }}
          >
            {trackName || "Loading..."}
          </h3>
          <span
            className="badge text-uppercase bg-green-dark font-8 mb-1"
            style={{ width: "fit-content" }}
          >
            {genres ? genres[0] : "Loading..."}
          </span>
          <p
            dir="auto"
            className="font-11 submitter-text"
            style={{ textOverflow: "ellipsis" }}
          >
            {displaySubmitterNote({ submitter, description })}
          </p>
        </div>
        <div className="align-self-center ms-auto">
          {showDiscountedPrice && (
            <p
              key={submitterPrice}
              style={{ width: "fit-content" }}
              className="font-16 font-400 pb-1 me-2 strike-through ms-auto mb-0"
            >
              ${submitterPrice}
            </p>
          )}
          <div
            key={formattedPrice}
            className={`btn btn-border btn-xs btn-full rounded-s text-uppercase font-900 bg-theme position-relative
            ${appRemoved
                ? "border-red-light color-red-light"
                : "border-highlight-light color-highlight-light"
              }`}
          >
            <div className={`${hasInAppPurchases ? "plus-sign" : ""}`}>
              {appRemoved
                ? t.previewApp.removed
                : price === 0
                  ? t.previewApp.free
                  : price
                    ? `$${price}`
                    : t.previewApp.view}
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  )
}

export default AppsListItem
