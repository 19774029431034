import { useEffect } from "react"
import useGlobalStore from "stores/global"

declare global {
  interface Window {
    adsbygoogle: any
  }
}

const AdBanner = (props: any) => {
  const showAds = useGlobalStore((state) => state.showAds)

  useEffect(() => {
    if (typeof window === "undefined" || !showAds) return
    try {
      ; (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (err) {
      console.log(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adkey])

  if (!showAds) return null
  return (
    <ins
      key={props.adkey}
      className="adsbygoogle"
      style={{
        ...props.style,
        display: "block",
        overflow: "hidden",
      }}
      data-ad-client="ca-pub-8210764995783356"
      {...props}
    />
  )
}
export default AdBanner
