interface Props {
  rating?: number
}

const RatingStars = ({ rating }: Props) => {
  if (rating) {
    switch (true) {
      case rating < 1:
        return (
          <>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating == 1:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating <= 2:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating <= 3:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating <= 4:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating < 5:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-gray-dark fa fa-star"></i>
          </>
        )
      case rating == 5:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
          </>
        )
      default:
        return (
          <>
            <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
            <i className="float-start color-yellow-dark fa fa-star"></i>
          </>
        )
    }
  } else
    return (
      <>
        <i className="float-start color-yellow-dark ps-2 fa fa-star"></i>
        <i className="float-start color-yellow-dark fa fa-star"></i>
        <i className="float-start color-yellow-dark fa fa-star"></i>
        <i className="float-start color-yellow-dark fa fa-star"></i>
        <i className="float-start color-yellow-dark fa fa-star"></i>
      </>
    )
}

export default RatingStars
