import { memo, useEffect, useState } from "react"
import NoSsr from "components/NoSsr"
import useT from "utils/useT"
import useUnsavedStore from "stores/unsaved"

function ShareMenu() {
  const t = useT()
  const toggleShareMenu = useUnsavedStore((state) => state.toggleShareMenu)
  const sharedApp = useUnsavedStore((state) => state.app)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [hostname, setHostname] = useState("")

  useEffect(() => {
    setHostname(window.location.href)
  }, [])

  const copyToClipboard = () => {
    console.log(sharedApp?.submitterPrice, sharedApp?.price)

    const oldPrice =
      sharedApp?.submitterPrice && sharedApp?.price === 0
        ? `${t.share.freeForLimitedTime}
${t.share.oldPrice} ${sharedApp?.submitterPrice}$
    `
        : ""

    const textToShare = `${sharedApp?.trackName || ""}
${oldPrice}
${sharedApp?.submitters?.[0]?.submitterDescription}
${hostname}
`
    navigator?.clipboard?.writeText(textToShare)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }
  const openShareSheet = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Appollow app link",
          url: hostname,
        })
        .then(() => {
          console.log("Thanks for sharing!")
        })
        .catch(console.error)
      toggleShareMenu()
    } else {
      console.log("WebShare API not supported.")
    }
  }
  return (
    <NoSsr>
      <h3 className="text-center font-700 font-26 my-3 pt-3">
        {t.shareMenu.shareApp}
      </h3>
      <p className="boxed-text-xl under-heading">{t.shareMenu.shareSub}</p>
      <div className="divider divider-margins mb-3"></div>
      <div className="px-4">
        <div className="row text-center mb-0">
          <div className="col-3 mb-n2">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${hostname}`}
              target="_blank"
              className="external-link shareToFacebook icon icon-l bg-facebook rounded-s shadow-l"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">Facebook</p>
          </div>
          <div className="col-3 mb-n2">
            <a
              href={`https://twitter.com/share?text=${hostname}`}
              className="external-link shareToTwitter icon icon-l bg-twitter rounded-s shadow-l"
            >
              <i className="fab fa-twitter font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">Twitter</p>
          </div>
          <div className="col-3 mb-n2">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${hostname}`}
              className="external-link shareToLinkedIn icon icon-l bg-linkedin rounded-s shadow-l"
            >
              <i className="fab fa-linkedin-in font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">LinkedIn</p>
          </div>
          <div className="col-3 mb-n2">
            <a
              href={`mailto:?body=${hostname}`}
              className="external-link shareToMail icon icon-l bg-mail rounded-s shadow-l"
            >
              <i className="fa fa-envelope font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">{t.shareMenu.email}</p>
          </div>
          <div className="col-3 mb-n2">
            <a
              href={`whatsapp://send?text=${hostname}`}
              className="external-link shareToWhatsApp icon icon-l bg-whatsapp rounded-s shadow-l"
            >
              <i className="fab fa-whatsapp font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">WhatsApp</p>
          </div>
          <div className="col-3 mb-n2" onClick={copyToClipboard}>
            <a className="shareToCopyLink icon icon-l bg-blue-dark rounded-s shadow-l">
              {isCopied ? (
                <i className="fa fa-check copied font-22"></i>
              ) : (
                <i className="fa fa-link not-copied font-22"></i>
              )}
              <br />
            </a>
            <p className="font-11 opacity-70">{t.shareMenu.copy}</p>
          </div>
          <div onClick={openShareSheet} className="col-3 mb-n2">
            <a className="external-link icon icon-l bg-red-dark rounded-s shadow-l">
              <i className="fas fa-share font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">{t.shareMenu.other}</p>
          </div>
          <div className="col-3 mb-n2">
            <a
              onClick={toggleShareMenu}
              className="close-menu icon icon-l bg-pinterest rounded-s shadow-l"
            >
              <i className="fa fa-times font-22"></i>
              <br />
            </a>
            <p className="font-11 opacity-70">{t.shareMenu.close}</p>
          </div>
        </div>
      </div>
      <div className="divider divider-margins mt-n1 mb-3"></div>
      <p className="text-center font-10 pb-3">{t.footer.copyright}</p>
    </NoSsr>
  )
}
export default memo(ShareMenu)
