import { useRouter } from "next/router"
import useRouterStore from "stores/routerStore"
import useT from "./useT"

export default function TimeAgo(time) {
  const t = useT()
  // const locale = useRouterStore((state) => state.router?.locale)
  const { locale } = useRouter()

  switch (typeof time) {
    case "number":
      break
    case "string":
      time = +new Date(time)
      break
    case "object":
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }
  var time_formats = [
    [60, t.previewApp.submitter.date.seconds, 1], // 60
    [120, t.previewApp.submitter.date.minAgo, "1 minute from now"], // 60*2
    [3600, t.previewApp.submitter.date.minutes, 60], // 60*60, 60
    [7200, t.previewApp.submitter.date.hourAgo, "1 hour from now"], // 60*60*2
    [86400, t.previewApp.submitter.date.hours, 3600], // 60*60*24, 60*60
    [172800, t.previewApp.submitter.date.yesterday, "Tomorrow"], // 60*60*24*2
    [604800, t.previewApp.submitter.date.days, 86400], // 60*60*24*7, 60*60*24
    [1209600, t.previewApp.submitter.date.lastWeek, "Next week"], // 60*60*24*7*4*2
    [2419200, t.previewApp.submitter.date.weeks, 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, t.previewApp.submitter.date.lastMonth, "Next month"], // 60*60*24*7*4*2
    [29030400, t.previewApp.submitter.date.months, 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, t.previewApp.submitter.date.lastYear, "Next year"], // 60*60*24*7*4*12*2
    [2903040000, t.previewApp.submitter.date.years, 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, t.previewApp.submitter.date.lastCentury, "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, t.previewApp.submitter.date.centuries, 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  var seconds = (+new Date() - time) / 1000,
    token = t.previewApp.submitter.date.ago,
    list_choice = 1

  if (seconds <= 10) {
    return t.previewApp.submitter.date.justNow
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = "from now"
    list_choice = 2
  }
  var i = 0,
    format
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice]
      if (locale?.includes("ar"))
        return token + " " + Math.floor(seconds / format[2]) + " " + format[1]
      return Math.floor(seconds / format[2]) + " " + format[1] + " " + token
    }
  return time
}
