import { AppInterface } from "types/types"
import AppsListItem from "./AppsListItem"
import RenderIfVisible from "react-render-if-visible"
import Spinner from "./Spinner"
import useT from "utils/useT"
import useGlobalStore from "stores/global"
import AdBanner from "./AdBanner"

interface Props {
  title: string
  apps?: AppInterface[]
  isLoading?: boolean
  noCard?: boolean
}

const AppsList = ({ title, apps, isLoading, noCard }: Props) => {
  const t = useT()
  const showAds = useGlobalStore((state) => state.showAds)
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <div>
      <div className={noCard ? "" : "card card-style"}>
        <div className="content mb-0">
          {title && (
            <h2 dir="auto" className="font-18 font-600 color-highlight pb-3">
              {title}
            </h2>
          )}

          {apps && apps?.length > 0 ? (
            <div className="apps-list-container">
              {apps?.map((app: AppInterface, index: number) => (
                <div key={index + Number(app.trackId) + title}>
                  <RenderIfVisible>
                    <div className="px-2 list-item">
                      <AppsListItem
                        trackId={app.trackId}
                        artworkUrl512={app.artworkUrl512}
                        trackName={app.trackName}
                        genres={app.genres}
                        description={app.description}
                        price={app.price}
                        formattedPrice={app.formattedPrice}
                        submitterPrice={app.submitterPrice}
                        submitterDescription={app.submitterDescription}
                        submitter={app.submitters?.[0] || app.submitter}
                        i={index}
                        appRemoved={app.appRemoved}
                        hasInAppPurchases={app.hasInAppPurchases}
                      />
                    </div>
                  </RenderIfVisible>
                  {showAds && index % 4 === 0 && (
                    <>
                      <div className="divider mb-0"></div>
                      <div style={{ maxHeight: 100 }}>
                        <AdBanner
                          adkey={app.trackId + "ad"}
                          data-ad-format="fluid"
                          data-ad-layout-key="-i8+5+s-64+a1"
                          data-ad-client="ca-pub-8210764995783356"
                          data-ad-slot={isDark ? "6859664652" : "5609597164"}
                        />
                      </div>
                    </>
                  )}
                  {apps.length > 1 && apps[index + 1] !== undefined && (
                    <div className="divider mb-0"></div>
                  )}
                </div>
              ))}
              <div className="d-flex justify-content-center mb-3">
                {isLoading && <Spinner size={60} />}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-5">
              {t.emptyList}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AppsList
