import { useState, useEffect, useRef } from "react"
import useT from "utils/useT"

export default function TruncatedText({ text = "" }) {
  const t = useT()
  const [showFullText, setShowFullText] = useState(false)
  const [truncatedText, setTruncatedText] = useState("")
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return
    const availableHeight = container?.clientHeight
    const lineHeight = parseInt(getComputedStyle(container).lineHeight)
    const maxLines = Math.floor(availableHeight / lineHeight)
    const ellipsis = "..."

    const words = text.split(" ")
    const truncatedWords = []
    let numLines = 0

    for (let i = 0; i < words.length; i++) {
      truncatedWords.push(words[i])
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = truncatedWords.join(" ") + ellipsis
      container.appendChild(tempDiv)
      const tempHeight = tempDiv.clientHeight
      container.removeChild(tempDiv)
      if (tempHeight > availableHeight || numLines >= maxLines) {
        break
      }
      if (i === words.length - 1) {
        setShowFullText(true)
      }
      if (words[i].endsWith("\n")) {
        numLines++
      }
    }

    setTruncatedText(truncatedWords.join(" ") + ellipsis)
  }, [text])

  return (
    <p ref={containerRef} style={{ height: "100%", margin: 0 }}>
      <span>{truncatedText}</span>
      {!showFullText && (
        <b className="text-primary"> {t.previewApp.submitter.seeMore} </b>
      )}
    </p>
  )
}
