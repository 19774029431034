import { useEffect } from "react"
import useSnackbarStore from "../stores/snackbar"
import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import useT from "./useT"

export default function useQueryPlus<T>(options: UseQueryOptions<T>) {
  const t = useT()
  const toggleToast = useSnackbarStore((state) => state.toggleToast)

  const query = useQuery<T>(options)
  const { error, isError, failureReason } = query

  useEffect(() => {
    if (isError || error || failureReason) {
      console.log({ error, failureReason })
      toggleToast({
        text: t.snackbar.error,
        color: "red",
      })
    }
  }, [isError, error, failureReason])

  return query
}
