export default function getLink(trackId: number | string, trackName?: string) {
  // Replace spaces with dashes and convert to lowercase
  const slug = trackName?.toLowerCase().replace(/\s+/g, "-")
  // Remove consecutive dashes and special characters
  const cleanedSlug = slug
    ?.replace(/[!@#$%^&*⋆()—°,.?":{}|<>]/g, "-")
    .replace(/-{2,}/g, "-")

  return `/apps/${trackId}/${trackName ? cleanedSlug : ""}`
}
