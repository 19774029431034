import { AppInterface } from "types/types"
import Image from "next/image"
import AppActionArea from "./AppActionArea"
import DetailsArea from "./DetailsArea"
import imagePlaceholder from "utils/imagePlaceholder"
import useGlobalStore from "stores/global"

interface Props {
  app?: AppInterface
}

const MainAppCard = ({ app }: Props) => {
  const screenshotUrls = app?.screenshotUrls
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <div className="main-app-card">
      <div className="card card-style">
        <div
          className="card mb-0"
          style={{
            height: "265px",
          }}
        >
          <Image
            src={screenshotUrls?.[0] || imagePlaceholder(isDark)}
            fill
            alt="app screenshot"
            style={{ objectFit: "cover" }}
            placeholder="blur"
            blurDataURL={imagePlaceholder(isDark)}
            priority
          />
          <AppActionArea app={app} />
          <div className="card-overlay bg-gradient-fade rounded-0"></div>
        </div>
        <DetailsArea app={app} />
      </div>
    </div>
  )
}

export default MainAppCard
