import dynamic from "next/dynamic"
import { useState } from "react"
import useSessionStore from "stores/session"
import useUnsavedStore from "stores/unsaved"
import { AppInterface } from "types/types"
import bytesToSize from "utils/bytesToSize"
import timeAgo from "utils/timeAgo"
import useT from "utils/useT"
import RatingStars from "../RatingStars"
import AdBanner from "components/AdBanner"

const DeleteApp = dynamic(() => import("../DeleteApp"))

type Props = {
  app?: AppInterface
  isMobile?: boolean
}

const DetailsArea = ({ app, isMobile }: Props) => {
  const t = useT()
  const [longText, setLongText] = useState(true)
  const isAdmin = useSessionStore((state) => state.user.isAdmin)
  const toggleShareMenu = useUnsavedStore((state) => state.toggleShareMenu)
  const saveAppDetails = useUnsavedStore((state) => state.saveAppDetails)

  const description = app?.description
  const trackContentRating = app?.trackContentRating
  const version = app?.version
  const fileSizeBytes = app?.fileSizeBytes
  const averageUserRating = app?.averageUserRating
  const userRatingCount = app?.userRatingCount
  const priceUpdateDate = app?.priceUpdated

  const getRatingsCount = (count: number | undefined) => {
    if (!!!count) {
      return 0
    }
    if (count > 1000) {
      return `${Math.floor(count / 1000)}K`
    }
    if (count > 1000000) {
      return `${Math.floor(count / 1000000)}M`
    }
    return count
  }
  return (
    <div
      className={`rounded-m bg-inherit z-10 p-3 m-0 ${
        isMobile ? "mt-n0" : "mt-n4"
      }`}
    >
      <AdBanner
        className="adsbygoogle"
        data-ad-format="auto"
        data-ad-client="ca-pub-8210764995783356"
        data-ad-slot="3118301516"
        data-ad-responsive="true"
      />
      {/* <h5 dir="auto" className="font-13 font-600">
        {t.previewApp.description}
      </h5> */}
      <p
        dir="auto"
        onClick={() => setLongText(!longText)}
        className={longText ? " shrink-text text-break" : " text-break"}
      >
        {description}
      </p>

      {Boolean(description && description?.length > 200) && (
        <span
          dir="auto"
          onClick={() => setLongText(!longText)}
          className="my-4 mt-n3 d-block color-highlight-light"
        >
          {longText ? t.previewApp.showMore : t.previewApp.showLess}
        </span>
      )}
      <div className="d-flex pe-2">
        <div>
          <p className="font-10 mb-n2">{t.previewApp.ageRating}</p>
          <h5 className="font-13 font-400">{trackContentRating}</h5>
        </div>
        <div className="ms-auto">
          <p className="font-10 mb-n2">{t.previewApp.version}</p>
          <h5 className="font-13 font-400">{version}</h5>
        </div>
        <div className="ms-auto">
          <p className="font-10 mb-n2">{t.previewApp.size}</p>
          <h5 className="font-13 font-400">
            {fileSizeBytes ? bytesToSize(fileSizeBytes) : "unknown"}
          </h5>
        </div>
        {priceUpdateDate && (
          <div className="ms-auto">
            <p className="font-10 mb-n2">{t.previewApp.priceUpdated}</p>
            <h5 dir="auto" className="font-13 font-400">
              {timeAgo(new Date(priceUpdateDate))}
            </h5>
          </div>
        )}
      </div>
      <div className="divider my-2"></div>
      <div className="d-flex">
        <div>
          <p className="mb-n1 font-10">{t.previewApp.ratingsReviews}</p>
          <div className="d-flex align-items-center">
            <h6 className="mb-0">{averageUserRating?.toFixed(2)}</h6>
            <RatingStars rating={averageUserRating} />
            <p className="m-auto ms-2">
              {`${getRatingsCount(userRatingCount)} ${t.previewApp.ratings}`}{" "}
            </p>
          </div>
        </div>
        <div className="ms-auto">
          {isAdmin && <DeleteApp id={app?.trackId} />}
          <div
            onClick={() => {
              toggleShareMenu()
              saveAppDetails(app)
            }}
            data-menu="menu-share"
            className="icon icon-s mt-2 rounded-m bg-highlight color-white"
          >
            <i className="fa fa-share-alt"></i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsArea
