import { motion } from "framer-motion"
import Image from "next/image"
import React from "react"
import useGlobalStore from "stores/global"
import { AppInterface } from "types/types"
import { event } from "utils/gtag"
import imagePlaceholder from "utils/imagePlaceholder"
import useT from "utils/useT"
import InAppPurchasesChip from "./InAppPurchasesChip"

type Props = {
  app?: AppInterface
  isMobile?: boolean
}

const AppActionArea = ({ app, isMobile }: Props) => {
  const t = useT()
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const isDark = useGlobalStore((state) => state.isDark)
  const price = app?.price
  const submitterPrice = app?.submitterPrice
  const artworkUrl512 = app?.artworkUrl512
  const trackName = app?.trackName
  const sellerName = app?.sellerName
  const trackViewUrl = app?.trackViewUrl
  const appRemoved = app?.appRemoved
  const hasInAppPurchases = app?.hasInAppPurchases
  const hasMonthlySubscriptions = app?.hasMonthlySubscriptions
  const showDiscountedPrice = Number(price) < Number(submitterPrice || 0)
  const isFree = Number(price) === 0

  return (
    <>
      <div className={`card-bottom ${isMobile ? "" : "pb-5 ps-3"} d-flex`}>
        <div className="me-3">
          <Image
            className="rounded-m"
            src={artworkUrl512 || imagePlaceholder(isDark)}
            width={90}
            height={90}
            alt="app icon"
            placeholder="blur"
            blurDataURL={imagePlaceholder(isDark)}
            priority
          />
        </div>
        <div className="w-100">
          <h1
            style={{ lineHeight: 1.2 }}
            className="font-18 pt-1 pb-0 app-detail-name"
          >
            {trackName}
          </h1>
          <h2
            style={{ lineHeight: 1.2, paddingRight: "5rem" }}
            className="font-300 font-12 app-detail-name"
          >
            {sellerName}
          </h2>
          <InAppPurchasesChip
            isFree={isFree}
            hasMonthlySubscriptions={hasMonthlySubscriptions}
            hasInAppPurchases={hasInAppPurchases}
          />
        </div>
      </div>
      <div className={`card-bottom  ${isMobile ? "" : "pb-5 ps-3 pe-3"}`}>
        <h3 className="font-25 text-end mb-3">
          {showDiscountedPrice && (
            <motion.div
              style={{ width: "fit-content" }}
              className="me-2 font-20 font-400 pb-1 strike-through ms-auto "
              initial={
                animationToggle && {
                  y: 30,
                  opacity: 0,
                }
              }
              animate={
                animationToggle && {
                  y: [30, -5, 0],
                  opacity: 1,
                }
              }
              transition={
                animationToggle
                  ? {
                      duration: 0.3,
                      type: "keyframes",
                      ease: "easeOut",
                      delay: 0.3,
                    }
                  : undefined
              }
            >
              ${submitterPrice}
            </motion.div>
          )}
          <a
            href={trackViewUrl}
            onClick={() =>
              event({
                action: appRemoved
                  ? "deleted_app_download_click"
                  : "download_app_click",
                category: "app preview",
                label: "app preview click",
                value: `App Name: ${trackName}`,
              })
            }
            target="_blank"
            className={`btn btn-border rounded-s text-uppercase font-600 position-relative ${
              appRemoved
                ? "border-red-light color-red-light"
                : "border-highlight-light color-highlight-light"
            }`}
            rel="noreferrer"
          >
            <div className={`${hasInAppPurchases ? "plus-sign" : ""}`}>
              {appRemoved
                ? t.previewApp.removed
                : price === 0
                ? t.previewApp.free
                : price
                ? `$${price}`
                : t.previewApp.view}
            </div>
          </a>
        </h3>
      </div>
    </>
  )
}

export default AppActionArea
