import { motion } from "framer-motion"

type Props = {
  children: React.ReactNode
  close: () => void
  disableDragUp: boolean
}

const DragClose = ({ children, close, disableDragUp }: Props) => {
  const swipeConfidenceThreshold = 5000
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  }
  return (
    <motion.div
      drag="y"
      className="ios-no-scroll"
      onTouchMove={(e) => e.preventDefault()}
      dragConstraints={{ top: 0, left: 0, right: 0 }}
      dragElastic={disableDragUp ? { top: 0 } : undefined}
      dragSnapToOrigin
      dragMomentum={false}
      dragTransition={{ bounceStiffness: 1000, bounceDamping: 90 }}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.y, velocity.y)
        if (swipe > swipeConfidenceThreshold) {
          close()
        }
      }}
    >
      {children}
    </motion.div>
  )
}

export default DragClose
